@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

header.login {
  height: 20rem;
  position: static;
  display: none;

  .biglogo {
    @include themify($themes) {
      background: themed('bigLogoBg');
    }

    //background: url(../gfx/esticrm-logo.png) no-repeat center center;
  }

  &.dzienkobiet .biglogo {
    background: url(../gfx/annual/dzienkobiet/bpm-estate-logo-big.png) no-repeat center center;
  }

  &.wielkanoc .biglogo {
    background: url(../gfx/annual/wielkanoc/bpm-estate-logo-big.png) no-repeat center center;
  }

  &.bozenarodzenie .biglogo {
    background: url(../gfx/annual/bozenarodzenie/bpm-estate-logo-big.png) no-repeat center center;
  }

  &.bozenarodzenie {
    background: $menu-bg-color url(../gfx/annual/bozenarodzenie/login-background.png) no-repeat right bottom;
  }

}

section.content.nobg {
  background: none;
  background-size: auto;
}

section.content.content-login {
  display: table;
  height: 100%;
  min-height: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  vertical-align: middle;
  background: url(../gfx/login/bg1.jpg) no-repeat center center;
  background-size: cover;

  article {
    display: table-cell;
    vertical-align: middle;

    .login-box {
      background: url(../gfx/login-background.png) repeat center center;
      padding: 2rem;
      width: 34rem;
      margin: -10rem auto 0;
    }

    .register-box {
      padding: 2rem;
      width: 74rem;
    }

    .error-box {
      width: 70rem;
      margin: -10rem auto 0;
    }
  }
}

.login-split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;

  .btn-primary {
    @include themify($themes) {
      background-color: themed('startScreenBtnBg');
      border-color: themed('startScreenBtnBg');
    }
  }
}

.login-left {
  left: 0;
  background-color: $white-color;
}

.toggle-form {
  min-height: 480px;
}

.login-right {
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.theme-prime .login-right {
  background-size: contain;
}

.inside-logo {
  display: block;
  margin-bottom: 2rem;

  .biglogo {
    width: 100%;
    height: 10rem;

    @include themify($themes) {
      background: themed('bigLogoBg');
    }
  }

  &.bozenarodzenie .biglogo {
    height: 12rem;
    background: url(../gfx/annual/bozenarodzenie/bpm-estate-logo-big.png) no-repeat center center;
  }

  &.dzienkobiet .biglogo {
    height: 15rem;
    background: url(../gfx/annual/dzienkobiet/bpm-estate-logo-big.png) no-repeat center center;
    position: static;
  }

  &.wielkanoc .biglogo {
    height: 15rem;
    background: url(../gfx/annual/wielkanoc/bpm-estate-logo-big.png) no-repeat center center;
    position: static;
  }

  &.ity .biglogo {
    height: 12rem;
    background: url(../gfx/annual/ity.png) no-repeat center center;
  }

}

#localmlskw .inside-logo .biglogo,
#mlskw .inside-logo .biglogo {
  background: url(../gfx/mls-kw-logo-big.png) no-repeat center center;
}

.error-box {
  background: url(../gfx/login-background.png) repeat center center;
  padding: 2rem;
  width: 70rem;
  color: $white-color;
}

.login-box {

  @include themify($themes) {
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
  }

  .input-group-addon {
    display: none;
    color: #BBBBBB;
    @include fts16;
    font-weight: normal;
    line-height: 1;
    padding: 0.6rem 1.2rem;
    width: 4rem;
  }

  .input-group {
    color: $white-color;
  }

  .form-control {
    height: 4rem;
    line-height: 4rem;
    text-align: center;
  }

  .btn {
    padding: 0.8rem;
  }

  .bpm-checkbox-contidions span {
    @include themify($themes) {
      color: themed('loginReminderColor');
    }
  }

}

.reminder-link a {
  @include themify($themes) {
    color: themed('loginReminderColor');
  }
}

.reminder-text {
  color: $white-color;
}

.start-screen {
  background: $white-color;

  .login-box {
    padding: 0 2rem;
    width: 34rem;

    .form-control {
      text-align: left;
      box-shadow: none;
      border-color: $table-border-hover;

      &::-webkit-input-placeholder {
        color: $black-color;
      }

      &:focus {
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .input-group {
      color: $gray-color;
    }

    //.bpm-checkbox span {
    //  color: $gray-color;
    //}

    .remember-me-checkbox {
      text-align: left;
    }

    .bpm-checkbox-contidions span {

      @include themify($themes) {
        color: themed('linkColor');
      }

      @include fts12;
    }

    .btn {
      margin-top: 3rem;

    }

    &.mobile-login-box {
      padding: 0 1rem !important;
    }

  }

  .error-box {
    padding: 0 2rem;
    width: 100%;
    max-width: 50rem;
    background: none;
    color: $gray-color;
  }

  .login-left {
    text-align: center;
    position: relative;
    width: 50%;

    .login-label {
      @include fts11;
      text-transform: uppercase;
      display: block;
      text-align: left !important;
    }
  }

  .login-right {
    width: 50%;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    @include center-position;
  }

  .reminder-link {
    padding-top: 0.4rem;
    text-align: right;

    a {
      @include themify($themes) {
        color: themed('linkColor');
      }

      @include fts12;
    }
  }

  .bpm-notification {
    z-index: 2;
  }
}

@media (max-width: 780px) {

  .start-screen {
    padding-top: 2rem;

    .login-left {
      position: relative;
      width: 100%;
      padding-bottom: 3rem;
    }

    .login-right {
      min-height: 40rem;
    }

    .login-box {
      width: 100%;
      max-width: 32rem;
      margin: 0 auto;
      padding: 0;
    }

    .login-split {
      width: 100%;
      height: auto;
      z-index: 1;
      position: relative;
    }

    .reminder-link {
      text-align: center;
      padding-top: 1rem;
    }

    .vertical-center {
      position: relative;
      left: 0;
      top: 0;
      -ms-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0);
    }
  }

  header.login {
    height: 10rem;
  }

  section.content.content-login {

    height: calc(100% - 10rem);

    .input-group.form-group .form-control {
      height: 4rem;
      line-height: 4rem;
    }

    .btn {
      height: 4rem;
    }

  }

}